// @ts-nocheck
import documentIcon from "../assets/fi_1001371.svg"; //"./better-bot-ai-questions/sustainability-2.svg";
import textIcon from "../assets/fi_2207562.svg"; //"./better-bot-ai-questions/sustainability-2.svg";

/**
 * Get an array of unique property values from an array of objects.
 * @param data The array of objects to search.
 * @param key The property name to search for unique values.
 * @returns An array of unique property values of type K.
 */
export const getUniquePropertyValues = <K extends keyof any>(data: Record<K, any>[], key: K): K[] => {
    // Use a Set to store unique property values
    const uniqueValues = new Set<K>(); // Define the Set with the property type

    // Iterate over the data array and add the property value to the Set
    data.forEach(item => {
        uniqueValues.add(item[key]);
    });

    // Convert the Set back to an Array if you need to work with an array
    return Array.from(uniqueValues) || [];
};

import { useNavigate } from "react-router-dom";
import { stringifyReqBody } from "../api";
import Cookies from "js-cookie";
import { USER_INACTIVE_TIMEOUT } from "../constants";

export async function makeStreamingApiRequest(api, request, setIsLoading, setAnswers, setStreamEnd, setError, lastQuestion) {
    try {
        const response = await fetch(api, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "text/event-stream"
            },
            body: stringifyReqBody(request)
        });

        if (!response.ok) {
            setError(`Fail to fetch response`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        checkResStatus(response, setError);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let rawData = "";

        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                // console.log("Stream complete");
                setStreamEnd(true);
                break;
            }
            rawData += decoder.decode(value, { stream: true });
            rawData = processStreamData(rawData, setIsLoading, setAnswers, lastQuestion);
        }
    } catch (error) {
        setError(`Fail to fetch response`);
        console.error("Fetch error:", error.message);
    }
}

function processStreamData(rawData, setIsLoading, setAnswers, lastQuestion) {
    const lines = rawData.split("\n");
    for (let i = 0; i < lines.length - 1; i++) {
        const line = lines[i].trim();
        if (line) {
            setIsLoading(false);
            try {
                const parsedData = JSON.parse(line);
                processParsedData(parsedData, setAnswers, lastQuestion);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        }
    }
    // Return any incomplete data to be processed with the next chunk
    return lines[lines.length - 1];
}

// // Usage
// makeApiRequest("exampleName", exampleRequest, setIsLoading, setAnswers, setStreamEnd);

function processParsedData(parsedData, setAnswers, lastQuestion) {
    setAnswers(prevAnswers => {
        // Check if the last entry in the previous answers array has a messageID or id.
        const hasId = entry => entry?.messageID || entry?.id;
        const lastAnswer = prevAnswers.length > 0 ? prevAnswers[prevAnswers.length - 1] : null;

        // If the last answer has an ID, we append the new data; otherwise, we replace the last entry.
        if (lastAnswer && hasId(lastAnswer[1])) {
            return [...prevAnswers, [lastQuestion, parsedData]];
        } else if (prevAnswers.length > 0) {
            return [...prevAnswers.slice(0, -1), [lastQuestion, parsedData]];
        } else {
            return [[lastQuestion, parsedData]];
        }
    });
}

export function checkResStatus(res, setError) {
    // const navigate = useNavigate();
    if (res.ok && res.status === 200) {
        return;
    } else if (res.status === 403) {
        window.location.href("/login");
        setError("Please login to continue");
        throw new Error(apiUnauthorizedErrorMessage);
    } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        setError("fail to fetch response");
        throw new Error("Client side error");
    } else {
        setError("Server error");
        throw new Error("Server error");
    }
}
// {
//         text: "What is the GEA approach to sustainability?",
//         value: "What is the GEA approach to sustainability?",
//         image: sustainability,
//         type: "chat"
//     },

export function makeUseCaseShortQuestions(paramData) {
    const { FAQ_1 = "", FAQ_2 = "", FAQ_3 = "", FAQ_4 = "", FAQ_5 = "", FAQ_6 = "", IconImages = {} } = paramData;
    // const { icon_1 = "", icon_2 = "", icon_3 = "", icon_4 = "", icon_5 = "", icon_6 = "" } = Images;
    // const iconArray = [icon_1, icon_2, icon_3, icon_4, icon_5, icon_6];
    return [FAQ_1, FAQ_2, FAQ_3, FAQ_4, FAQ_5, FAQ_6]
        .filter(field => field && field.trim() !== "")
        .map((field, i) => ({
            text: field,
            value: field,
            image: IconImages[i], //i % 2 === 0 ? documentIcon : textIcon,
            type: "chat"
        }));
}

let timer = null;

/**
 * Starts the inactivity timer.
 */
export const startInactivityTimer = () => {
    // console.log("🚀 ~ startInactivityTimer ~ USER_INACTIVE_TIMEOUT:", USER_INACTIVE_TIMEOUT);

    timer = window.setTimeout(() => {
        navigateToLogin();
    }, USER_INACTIVE_TIMEOUT);
    // console.log("timer===>", timer);
};

/**
 * Clears the inactivity timer.
 */
export const clearInactivityTimer = () => {
    // console.log("🚀 ~ clearInactivityTimer ~ clearInactivityTimer:", { timer, USER_INACTIVE_TIMEOUT });

    if (timer) {
        window.clearTimeout(timer);
    }
};

/**
 * Resets the inactivity timer.
 */
export const resetInactivityTimer = () => {
    // console.log("resetInactivityTimer===>", timer);
    clearInactivityTimer();
    startInactivityTimer();
};

/**
 * Navigates to the login page.
 */
export const navigateToLogin = () => {
    // console.log("🚀 ~ navigateToLogin ~ navigateToLogin:", { timer, USER_INACTIVE_TIMEOUT, dateTime: new Date().toLocaleString() });
    Cookies.set("isUserLoggedIn", "False");
    if (sessionStorage.getItem("sessionMenus")) {
        sessionStorage.removeItem("sessionMenus");
    }
    window.location.href = "/logout";
    // navigate("/login");
};

export const checkUserLoggedIn = () => {
    const isUserLoggedIn = Cookies.get("isUserLoggedIn");
    // console.log("🚀 ~ checkUserLoggedIn ~ checkUserLoggedIn:", isUserLoggedIn);
    if (isUserLoggedIn == "False") {
        // navigate("/login");
        window.location.hash = "/login";
    } else {
        console.log("User is logged in");
    }
};

export const handleRemoveCosmosReference = async options => {
    const url = `/remove_document`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(options),
            credentials: "include"
        });
        const data = await response.json();
    } catch (error) {
        console.error("error===>", error);
    }
};

export const fetchWithRetry = async (url, options = {}, retries = 3) => {
    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            if (i === retries - 1) {
                throw error; // If it's the last retry, throw the error
            }
            console.warn(`Retrying... (${i + 1}/${retries})`);
        }
    }
};
