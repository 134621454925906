import fi_1001371 from "./fi_1001371.svg";
import fi_11325644 from "./fi_11325644.svg";
import fi_2207562 from "./fi_2207562.svg";
import fi_3573187 from "./fi_3573187.svg";
import fi_3621018 from "./fi_3621018.svg";
import fi_589975 from "./fi_589975.svg";
import fi_9972405 from "./fi_9972405.svg";

//ask-legal
import Indemnity from "./ask-legal/Indemnity.svg";
import Laws from "./ask-legal/Laws.svg";
import PersonalData from "./ask-legal/PersonalData.svg";
import agreement from "./ask-legal/agreement.svg";
import insurancePolicy from "./ask-legal/insurancePolicy.svg";
import professionalInsurance from "./ask-legal/professionalInsurance.svg";

//better-bot-ai-questions
import ecoLabel2 from "./better-bot-ai-questions/ecoLabel2.svg";
import engineeringWithPurpose2 from "./better-bot-ai-questions/engineeringWithPurpose-2.svg";
import itMarketing from "./better-bot-ai-questions/itMarketing.svg";
import mission26_2 from "./better-bot-ai-questions/mission26-2.svg";
import reduceEnergyAndWaterConsumption from "./better-bot-ai-questions/reduceEnergyAndWaterConsumption.svg";
import sustainability from "./better-bot-ai-questions/sustainability-2.svg";

//dalle3
import candyAndChocolateCity from "./dalle3/candy-n-chocolate-city.svg";
import newyorkDusk from "./dalle3/newyork-dusk.svg";
import renewableEnergy from "./dalle3/renewable-energy.svg";
import skyline from "./dalle3/skyline.svg";
import snowAndSunrise from "./dalle3/snow-n-sunrise.svg";
import vanGoghStarryNight from "./dalle3/van-gogh-starry-night.svg";

//GPT4Turbo
import buyers from "./GPT4Turbo/buyers.svg";
import climateChange from "./GPT4Turbo/climateChange.svg";
import dairyManufacture from "./GPT4Turbo/dairyManufacture.svg";
import eCommerce from "./GPT4Turbo/ecommerce.svg";
import motivation from "./GPT4Turbo/motivation.svg";
import successful from "./GPT4Turbo/successful.svg";

//GPT35Turbo
import dateNight from "./GPT35Turbo/dateNight.svg";
import geographyLessonPlan from "./GPT35Turbo/geographyLessonPlan.svg";
import growthMindset from "./GPT35Turbo/growthMindset.svg";
import httpRequestInJava from "./GPT35Turbo/httpRequestInJava.svg";
import personalTrainer from "./GPT35Turbo/personalTrainer.svg";
import quantumComputing from "./GPT35Turbo/quantumComputing.svg";

//helpAndFaq
import Limitations from "./helpAndFaq/Limitations.svg";
import accuracyAndTruthfulness from "./helpAndFaq/accuracyAndTruthfulness.svg";
import dataHandling from "./helpAndFaq/dataHandling.svg";
import handlingSensitivity from "./helpAndFaq/handlingSensitivity.svg";
import howToStartWithBetterBotAI from "./helpAndFaq/howToStartWithBetterBotAI.svg";
import howToUseBetterBotAILikePro from "./helpAndFaq/howToUseBetterBotAILikePro.svg";
import privacyAndSecurity from "./helpAndFaq/privacyAndSecurity.svg";
import userControl from "./helpAndFaq/userControl.svg";

//helpArticlesAndFaq
import astraunautArticle from "./helpArticlesAndFaq/astraunautArticle.svg";
import dairyProducts from "./helpArticlesAndFaq/dairyProducts.svg";
import ecoLabel from "./helpArticlesAndFaq/ecoLabel.svg";
import engineeringWithPurpose from "./helpArticlesAndFaq/engineeringWithPurpose.svg";
import goldenHour from "./helpArticlesAndFaq/goldenHour.svg";
import goldenHousr from "./helpArticlesAndFaq/goldenHousr.svg";
import mission26 from "./helpArticlesAndFaq/mission26.svg";
import orangeMonster from "./helpArticlesAndFaq/orangeMonster.svg";
import waterTreatment from "./helpArticlesAndFaq/waterTreatment.svg";
import { ChatThread, MenuItemType } from "../api";

//bing example list
export const bingExampleList = [
    {
        text: "Should I have a hometown wedding or a destination wedding?",
        value: "Should I have a hometown wedding or a destination wedding?",
        image: fi_9972405,
        type: "chat"
    },
    {
        text: "Where do people travel for culinary experiences?",
        value: "Where do people travel for culinary experiences?",
        image: fi_11325644,
        type: "chat"
    },
    {
        text: "Write a C# program to check if a number is prime",
        value: "Write a C# program to check if a number is prime",
        image: fi_3573187,
        type: "chat"
    },
    {
        text: "Write a joke that a toddler would find hilarious",
        value: "Write a joke that a toddler would find hilarious",
        image: fi_589975,
        type: "chat"
    },
    {
        text: "What's the next trend in fashion and where should I shop to find it?",
        value: "What's the next trend in fashion and where should I shop to find it?",
        image: fi_2207562,
        type: "chat"
    },
    {
        text: "Compose an evite for a baby shower that includes gift ideas",
        value: "Compose an evite for a baby shower that includes gift ideas",
        image: fi_1001371,
        type: "chat"
    },
    {
        text: "I'm looking for handmade furniture for my small apartment",
        value: "I'm looking for handmade furniture for my small apartment",
        image: fi_3621018,
        type: "chat"
    }
];

//example chat question lists
export const chatExampleList = [
    {
        text: "Can you tell me about the Add Better Ecolabel?",
        value: "Can you tell me about the Add Better Ecolabel?",
        type: "chat",
        image: ecoLabel2
    },
    {
        text: "What is the GEA approach to sustainability?",
        value: "What is the GEA approach to sustainability?",
        type: "chat",
        image: sustainability
    },
    {
        text: "What are the potential use cases of Generative AI at GEA for marketing and IT purposes?",
        value: "What are the potential use cases of Generative AI at GEA for marketing and IT purposes?",
        type: "chat",
        image: itMarketing
    },
    {
        text: "What is Mission 26?",
        value: "What is Mission 26?",
        type: "chat",
        image: mission26_2
    },
    {
        text: "What is Engineering with purpose?",
        value: "What is Engineering with purpose?",
        type: "chat",
        image: engineeringWithPurpose2
    },
    {
        text: "How is GEA leveraging R&D and sustainable solutions to reduce energy and water consumption?",
        value: "How is GEA leveraging R&D and sustainable solutions to reduce energy and water consumption?",
        type: "chat",
        image: reduceEnergyAndWaterConsumption
    },
    {
        tip: "Add adjectives to tweak the overall look of the image.",
        text: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        value: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        type: "image",
        image: astraunautArticle
    },
    {
        tip: "Be specific about what you want to see in an image.",
        text: "3D render of a cute orange monster on a dark blue background, digital art",
        value: "3D render of a cute orange monster on a dark blue background, digital art",
        type: "image",
        image: orangeMonster
    },
    {
        tip: "Consider aspects of imagery like lighting and perspective.",
        text: "A luxury Parisian penthouse, warm colors, golden hour",
        value: "A luxury Parisian penthouse, warm colors, golden hour",
        type: "image",
        image: goldenHour
    }
];

//dalle Example list
export const dalleExampleList = [
    {
        text: "Can you tell me about the Add Better Ecolabel?",
        value: "Can you tell me about the Add Better Ecolabel?",
        image: ecoLabel,
        type: "chat"
    },
    {
        text: "What is the GEA approach to sustainability?",
        value: "What is the GEA approach to sustainability?",
        image: sustainability,
        type: "chat"
    },
    {
        text: "Provide list of dairy products from GEA",
        value: "What is the GEA approach to sustainability?",
        image: dairyProducts,
        type: "chat"
    },
    {
        text: "What is Mission 26?",
        value: "What is Mission 26?",
        image: mission26,
        type: "chat"
    },
    {
        text: "What is Engineering with purpose?",
        value: "What is Engineering with purpose?",
        image: engineeringWithPurpose,
        type: "chat"
    },
    {
        text: "Bilge Water Treatment",
        value: "Bilge Water Treatment",
        image: waterTreatment,
        type: "chat"
    },
    {
        tip: "Add adjectives to tweak the overall look of the image.",
        text: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        value: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        image: astraunautArticle,
        type: "image"
    },
    {
        tip: "Be specific about what you want to see in an image.",
        text: "3D render of a cute orange monster on a dark blue background, digital art",
        value: "3D render of a cute orange monster on a dark blue background, digital art",
        image: orangeMonster,
        type: "image"
    },
    {
        tip: "Consider aspects of imagery like lighting and perspective.",
        text: "A luxury Parisian penthouse, warm colors, golden hour",
        value: "A luxury Parisian penthouse, warm colors, golden hour",
        image: goldenHour,
        type: "image"
    }
];

//dalle 3 example list
export const dalle3ExampleList = [
    {
        tip: "Visualize abstract business concepts.",
        text: "A symbolic representation of successful teamwork, featuring diverse individuals building a bridge together.",
        value: "A symbolic representation of successful teamwork, featuring diverse individuals building a bridge together.",
        image: fi_9972405,
        type: "image",
        link: true
    },
    {
        tip: "Use specific scenarios or events.",
        text: "A bustling trade fair booth showcasing the latest innovations in renewable energy.",
        value: "A bustling trade fair booth showcasing the latest innovations in renewable energy.",
        image: renewableEnergy,
        type: "image",
        link: true
    },
    {
        tip: "Visualize data in a creative way.",
        text: "An interactive 3D pie chart representing market share in the tech industry.",
        value: "An interactive 3D pie chart representing market share in the tech industry.",
        image: itMarketing,
        type: "image",
        link: true
    },
    {
        tip: "Use industry-specific elements.",
        text: "A modern and sleek conference room with a panoramic view of the New York skyline.",
        value: "A modern and sleek conference room with a panoramic view of the New York skyline.",
        image: skyline,
        type: "image",
        link: true
    },
    {
        tip: "Use literary references or famous works of art for inspiration.",
        text: "A starry night sky reminiscent of Van Gogh's painting, viewed from the window of a rustic countryside cottage.",
        value: "A starry night sky reminiscent of Van Gogh's painting, viewed from the window of a rustic countryside cottage.",
        image: vanGoghStarryNight,
        type: "image",
        link: true
    },
    {
        tip: "Combine different elements that wouldn't usually go together.",
        text: "A whimsical cityscape where the buildings are made of colorful candy and chocolate.",
        value: "A whimsical cityscape where the buildings are made of colorful candy and chocolate.",
        image: candyAndChocolateCity,
        type: "image",
        link: true
    },
    {
        tip: "Use precise descriptions of the subject's appearance.",
        text: "A majestic Bengal tiger lounging in a sun-dappled jungle, its coat gleaming with vibrant orange and black stripes.",
        value: "A majestic Bengal tiger lounging in a sun-dappled jungle, its coat gleaming with vibrant orange and black stripes.",
        image: fi_9972405,
        type: "image",
        link: true
    },
    {
        tip: "Specify the mood or atmosphere you want to convey.",
        text: "A tranquil Japanese garden bathed in soft moonlight, evoking a sense of calm.",
        value: "A tranquil Japanese garden bathed in soft moonlight, evoking a sense of calm.",
        image: fi_9972405,
        type: "image",
        link: true
    },
    {
        tip: "Incorporate weather elements for added realism.",
        text: "A serene snow-covered village in the Swiss Alps at sunrise.",
        value: "A serene snow-covered village in the Swiss Alps at sunrise.",
        image: snowAndSunrise,
        type: "image",
        link: true
    },
    {
        tip: "Use specific time periods and historical styles.",
        text: "A bustling 1920s New York street scene at dusk.",
        value: "A bustling 1920s New York street scene at dusk.",
        image: newyorkDusk,
        type: "image",
        link: true
    },
    {
        tip: "Add adjectives to tweak the overall look of the image.",
        text: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        value: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        image: astraunautArticle,
        type: "image",
        link: true
    },
    {
        tip: "Be specific about what you want to see in an image.",
        text: "3D render of a cute orange monster on a dark blue background, digital art",
        value: "3D render of a cute orange monster on a dark blue background, digital art",
        image: orangeMonster,
        type: "image",
        link: true
    },
    {
        tip: "Consider aspects of imagery like lighting and perspective.",
        text: "A luxury Parisian penthouse, warm colors, golden hour",
        value: "A luxury Parisian penthouse, warm colors, golden hour",
        image: goldenHour,
        type: "image",
        link: true
    }
];
// example list
export const examplesList = [
    {
        text: "Can you tell me about the Add Better Ecolabel?",
        value: "Can you tell me about the Add Better Ecolabel?",
        image: ecoLabel2,
        type: "chat"
    },
    {
        text: "What is the GEA approach to sustainability?",
        value: "What is the GEA approach to sustainability?",
        image: sustainability,
        type: "chat"
    },
    {
        text: "Provide list of dairy products from GEA",
        value: "What is the GEA approach to sustainability?",
        image: dairyProducts,
        type: "chat"
    },
    {
        text: "What is Mission 26?",
        value: "What is Mission 26?",
        image: mission26,
        type: "chat"
    },
    {
        text: "What is Engineering with purpose?",
        value: "What is Engineering with purpose?",
        image: engineeringWithPurpose,
        type: "chat"
    },
    {
        text: "Bilge Water Treatment",
        value: "Bilge Water Treatment",
        image: waterTreatment,
        type: "chat"
    },
    {
        tip: "Add adjectives to tweak the overall look of the image.",
        text: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        value: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        image: astraunautArticle,
        type: "image"
    },
    {
        tip: "Be specific about what you want to see in an image.",
        text: "3D render of a cute orange monster on a dark blue background, digital art",
        value: "3D render of a cute orange monster on a dark blue background, digital art",
        image: orangeMonster,
        type: "image"
    },
    {
        tip: "Consider aspects of imagery like lighting and perspective.",
        text: "A luxury Parisian penthouse, warm colors, golden hour",
        value: "A luxury Parisian penthouse, warm colors, golden hour",
        image: goldenHour,
        type: "image"
    }
];

//help articles list
export const helpArticlesExampleList = [
    {
        id: "1",
        image: accuracyAndTruthfulness,
        sortDec: `-   Generative AI strives for accuracy, but it's important to note that responses may not always be entirely correct.   It's a tool designed to assist and generate content based on patterns and data.<br />
        -   If you come across information that seems incorrect consider these steps:<br />
            &emsp14;&#8197;o	Verify independently`,
        title: "Accuracy and truthfulness",
        description: `
        <ul>-   Generative AI strives for accuracy, but it's important to note that responses may not always be entirely correct.   It's a tool designed to assist and generate content based on patterns and data.</ul>
        <ul>-   If you come across information that seems incorrect consider these steps:</ul>
            <li style="text-indent: 5em;">&emsp14;	Verify independently.</li>
            <li style="text-indent: 5em;">&emsp14;	Provide feedback.</li>
            <li style="text-indent: 5em;">&emsp14;	Understand that it works of pattern recognition and not a database of facts.</li>
        <ul>-   Use BetterBot AI as an assistant to your work not as your replacement.</ul>
        `
    },
    {
        id: "2",
        image: howToStartWithBetterBotAI,
        sortDec: `-   Starting your Conversation:<br />
        &emsp14;o	You can start the conversation with BetterBot with a greeting or directly with your question.<br/>
        -   Asking clear questions:<br />
        &emsp14;o	Ask clear and specific questions, this helps to receive accurate and relevant information.`,
        title: "How to start with BetterBot AI",
        description: `
        <ul>-   Starting your Conversation:</ul> 
            <li style="text-indent: 5em;">&emsp14;	You can start the conversation with BetterBot with a greeting or directly with your question. </li>
        <ul>-   Asking clear questions:</ul>
            <li style="text-indent: 5em;">&emsp14;	Ask clear and specific questions, this helps to receive accurate and relevant information.</li>
            <li style="text-indent: 5em;">&emsp14;	Avoid ambiguity, the precisely you ask your questions the more precisely will BetterBot respond.</li>
        <ul>-   Improving Questions:</ul>
            <li style="text-indent: 5em;">&emsp14;	If the initial response is not what you thought it would be, try refining your question or supply additional information and context.</li>
            <li style="text-indent: 5em;">&emsp14;	Think of it as a collaborative process, your inputs guide the conversation and better inputs result in better responses.</li>
        `
    },
    {
        id: "3",
        image: howToUseBetterBotAILikePro,
        sortDec: `-   Context Retention:<br/>
            &emsp14;&#8197;o	BetterBot AI can remember previous parts of the conversation.<br/>
            &emsp14;&#8197;o	Refer to those parts of the conversation to receive more coherent and contextual responses.`,
        title: "How to use BetterBot AI like a Pro",
        description: `
        <ul>-   Context Retention:</ul>
            <li style="text-indent: 5em;">&emsp14;	BetterBot AI can remember previous parts of the conversation.</li>
            <li style="text-indent: 5em;">&emsp14;	Refer to those parts of the conversation to receive more coherent and contextual responses.</li>
        <ul>-   Multi-Step Questions:</ul>
            <li style="text-indent: 5em;">&emsp14;	If you have any questions that are more complex, you can set up the conversation in a way that allows for better responses by providing step by step context and background information such as motivation or more specific results you expect. </li>
            <li style="text-indent: 5em;">&emsp14;	This will allow for more detailed and accurate interactions.</li>
        <ul>-   Use Keywords:</ul>
            <li style="text-indent: 5em;">&emsp14;	Using and repeating certain keywords can help BetterBot to understand what you are looking for.</li>
            <li style="text-indent: 5em;">&emsp14;	Emphasizing these keywords will help in receiving more helpful and relevant information.</li>

        <ul>-   Experimenting with your phrasing:</ul>
            <li style="text-indent: 5em;">&emsp14;	Getting creative with how you ask your questions or approach a task.</li>
            <li style="text-indent: 5em;">&emsp14;	This can help you in getting new perspectives and insights of your problem.</li>
        `
    },
    {
        id: "4",
        image: userControl,
        title: "User Control",
        sortDec: `-   Personalisation:<br/>
            &emsp14;&#8197;o	You are in control of the conversation; you can guide it towards where you want and end it at any time.<br/>
            &emsp14;&#8197;o	BetterBot will match your mannerism and way of speaking as well as your language.`,
        description: `
        <ul>-   Personalisation:</ul>
            <li style="text-indent: 5em;">&emsp14;	You are in control of the conversation; you can guide it towards where you want and end it at any time.</li>
            <li style="text-indent: 5em;">&emsp14;	BetterBot will match your mannerism and way of speaking as well as your language.</li>
        <ul>-   Feedback:</ul>
            <li style="text-indent: 5em;">&emsp14;	You can provide feedback to the system as well as to the responses of BetterBot, this will help improve the service as well as the responses you receive.</li>
        `
    },
    {
        id: "5",
        image: dataHandling,
        title: "Data Handling",
        sortDec: `-   Data Encryption:<br/>
            &emsp14;&#8197;o	Your data’s safety is the number one priority, understand that your conversations are secure throughout.<br/>
            -   Storage Policies:<br/>
            &emsp14;&#8197;o	Your data, your control. Learn about our transparent storage policies, including duration and how your information is managed after interactions.`,
        description: `
        <ul>-   Data Encryption:</ul>
            <li style="text-indent: 5em;">&emsp14;	Your data’s safety is the number one priority, understand that your conversations are secure throughout.</li>
        <ul>-   Storage Policies:</ul>
            <li style="text-indent: 5em;">&emsp14;	Your data, your control. Learn about our transparent storage policies, including duration and how your information is managed after interactions.</li>
        `
    },
    {
        id: "6",
        image: privacyAndSecurity,
        sortDec: `-   Secure Connection:<br/>
            &emsp14;&#8197;o	Your interactions with BetterBot are connected securely, this ensures a protected environment.<br/>
            -   Authentication Measurements:<br/>
            &emsp14;&#8197;o	Understand the authentication measures in place, ensuring you interact with a legitimate and secure system.`,
        title: "Privacy and Security",
        description: `
        <ul>-   Secure Connection:</ul>
            <li style="text-indent: 5em;">&emsp14;	Your interactions with BetterBot are connected securely, this ensures a protected environment.</li>
        <ul>-   Authentication Measurements:</ul>
            <li style="text-indent: 5em;">&emsp14;	Understand the authentication measures in place, ensuring you interact with a legitimate and secure system.</li>
        <ul>-   Incident Report Plan:</ul>
            <li style="text-indent: 5em;">&emsp14;	In the unlikely event of an incident a robust incident response plan in place to ensure your security and privacy</li>
        <ul>-   Regular Security Audits:</ul>
            <li style="text-indent: 5em;">&emsp14;	Security audits and system robustness checks are in place to ensure that the security of the service remains up to date and continues to work smoothly, while maintaining a safe environment for its users.</li>
        `
    },
    {
        id: "7",
        image: handlingSensitivity,
        sortDec: `-   Approaching with Sensitivity:<br/>
            &emsp14;&#8197;o	Consider framing questions and discussions with sensitivity to ensure a respectful interaction.<br/>
            -   Privacy Considerations:<br/>
            &emsp14;&#8197;o	Be cautious when asking the model about sensitive information. Avoid sharing personal or confidential details`,
        title: "Handling Sensitivity",
        description: `
        <ul>-   Approaching with Sensitivity:</ul>
            <li style="text-indent: 5em;">&emsp14;	Consider framing questions and discussions with sensitivity to ensure a respectful interaction.</li>
        <ul>-   Privacy Considerations:</ul>
            <li style="text-indent: 5em;">&emsp14;	Be cautious when asking the model about sensitive information. Avoid sharing personal or confidential details.</li>
        <ul>-   Response handling:</ul>
            <li style="text-indent: 5em;">&emsp14;	Understand how to interpret BetterBot’s responses when handling sensitive information.</li>
        `
    },
    {
        id: "8",
        image: Limitations,
        sortDec: `-   Know its Bounds:<br/>
             &emsp14;&#8197;o	Understand where BetterBot excels in and the specific areas it has limitations and might now cover as comprehensively.<br/>
             -   Complexity of Queries:<br/>
             &emsp14;&#8197;o	Sometimes simplifying or breaking up the query into step-by-step instructions can help in overcoming certain problems that stress the chat bot’s limits and enhance the response accuracy..
             `,
        title: "Limitations:",
        description: `
        <ul>-   Know its Bounds:</ul>
            <li style="text-indent: 5em;"> 
            &emsp14;&#8197;o	Understand where BetterBot excels in and the specific areas it has limitations and might now cover as comprehensively.</li>
        <ul>-   Complexity of Queries:</ul>
            <li style="text-indent: 5em;">&emsp14;	Sometimes simplifying or breaking up the query into step-by-step instructions can help in overcoming certain problems that stress the chat bot’s limits and enhance the response accuracy.</li>
        <ul>-   Dynamic Learning Curve:</ul>
            <li style="text-indent: 5em;">&emsp14;	Recognise that BetterBot is still learning. It may not have immediate knowledge of recent events and highly specific fields.</li>
        `
    },
    {
        id: "9",
        image: Limitations,
        title: "Limitations",
        sortDec: `-  Know its Bounds:<br/>
        &emsp14; o	Understand where BetterBot excels in and the specific areas it has limitations and might now cover as comprehensively.<br/>
        -  Complexity of Queries:<br/>
        &emsp14; o	Sometimes simplifying or breaking up the query into step-by-step instructions can help in overcoming certain problems that stress the chat bot’s limits and enhance the response accuracy..<br/>
        `,
        description: `
        <ul>-   Know its Bounds:</ul>
            <li style="text-indent: 5em;">&emsp14;    Understand where BetterBot excels in and the specific areas it has limitations and might now cover as comprehensively.</li>
        <ul>-   Complexity of Queries:</ul>
            <li style="text-indent: 5em;">&emsp14;    Sometimes simplifying or breaking up the query into step-by-step instructions can help in overcoming certain problems that stress the chat bot’s limits and enhance the response accuracy.</li>
        <ul>-   Dynamic Learning Curve:</ul>
            <li style="text-indent: 5em;">&emsp14;    Recognise that BetterBot is still learning. It may not have immediate knowledge of recent events and highly specific fields.</li>
        `
    }
];

//one shot questions list
export const oneShotExampleList = [
    {
        text: "Can you tell me about the Add Better Ecolabel?",
        value: "Can you tell me about the Add Better Ecolabel?",
        image: ecoLabel,
        type: "chat"
    },
    {
        text: "What is the GEA approach to sustainability?",
        value: "What is the GEA approach to sustainability?",
        image: sustainability,
        type: "chat"
    },
    {
        text: "Provide list of dairy products from GEA",
        value: "Provide list of dairy products from GEA",
        image: dairyProducts,
        type: "chat"
    },
    {
        text: "What is Mission 26?",
        value: "What is Mission 26?",
        image: mission26,
        type: "chat"
    },
    {
        text: "What is Engineering with purpose?",
        value: "What is Engineering with purpose?",
        image: engineeringWithPurpose,
        type: "chat"
    },
    {
        text: "Bilge Water Treatment",
        value: "Bilge Water Treatment",
        image: waterTreatment,
        type: "chat"
    },
    {
        tip: "Add adjectives to tweak the overall look of the image.",
        text: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        value: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        image: astraunautArticle,
        type: "image"
    },
    {
        tip: "Be specific about what you want to see in an image.",
        text: "3D render of a cute orange monster on a dark blue background, digital art",
        value: "3D render of a cute orange monster on a dark blue background, digital art",
        image: orangeMonster,
        type: "image"
    },
    {
        tip: "Consider aspects of imagery like lighting and perspective.",
        text: "A luxury Parisian penthouse, warm colors, golden hour",
        value: "A luxury Parisian penthouse, warm colors, golden hour",
        image: goldenHour,
        type: "image"
    }
];

//pure plus list
export const purePlusExampleList = [
    {
        tip: "Limited knowledge of world and events after 2021",
        text: "Write a detailed strategy for how I can stay motivated at work and maintain focus.",
        value: "Write a detailed strategy for how I can stay motivated at work and maintain focus.",
        image: motivation,
        type: "chat"
    },
    {
        tip: "May occasionally generate incorrect information",
        text: "Generate five checklist items for creating a successful retirement plan in your twenties.",
        value: "Generate five checklist items for creating a successful retirement plan in your twenties.",
        image: successful,
        type: "chat"
    },
    {
        tip: "May occasionally produce harmful instructions or biased",
        text: "Write me a 500-word blog post about tips on creating a social media content calendar for e-commerce. Make it informal and fun.",
        value: "Write me a 500-word blog post about tips on creating a social media content calendar for e-commerce. Make it informal and fun.",
        image: eCommerce,
        type: "chat"
    },
    {
        tip: "Trained to decline inappropriate requests",
        text: "Generate a list of 10 customer outreach topics that can be used to engage with potential buyers of a product or service.",
        value: "Generate a list of 10 customer outreach topics that can be used to engage with potential buyers of a product or service.",
        image: buyers,
        type: "chat"
    },
    {
        tip: "Allows user to provide follow-up corrections",
        text: "Develop a compelling thesis statement for an argumentative essay about climate change.",
        value: "Develop a compelling thesis statement for an argumentative essay about climate change.",
        image: climateChange,
        type: "chat"
    },
    {
        tip: "Ask follow up questions and engage",
        text: "Generate five ideas for using agile methodology to manage an online marketing campaign for dairy manufacturing.",
        value: "Generate five ideas for using agile methodology to manage an online marketing campaign for dairy manufacturing.",
        image: dairyManufacture,
        type: "chat"
    },
    {
        tip: "Add adjectives to tweak the overall look of the image.",
        text: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        value: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        image: astraunautArticle,
        type: "image"
    },
    {
        tip: "Be specific about what you want to see in an image.",
        text: "3D render of a cute orange monster on a dark blue background, digital art",
        value: "3D render of a cute orange monster on a dark blue background, digital art",
        image: orangeMonster,
        type: "image"
    },
    {
        tip: "Consider aspects of imagery like lighting and perspective.",
        text: "A luxury Parisian penthouse, warm colors, golden hour",
        value: "A luxury Parisian penthouse, warm colors, golden hour",
        image: goldenHour,
        type: "image"
    }
];
//list for pure
export const pureExampleList = [
    {
        tip: "Limited knowledge of world and events after 2021",
        text: "How do I make an HTTP request in JavaScript?",
        value: "How do I make an HTTP request in JavaScript?",
        image: httpRequestInJava,
        type: "chat"
        // title: "Examples",
        // image: "src/assets/sparkles.svg"
    },
    {
        tip: "May occasionally generate incorrect information",
        text: "Explain quantum computing in simple terms",
        value: "Explain quantum computing in simple terms",
        image: quantumComputing,
        type: "chat"
        // title: "Capabilities",
        // image: "src/assets/star.svg"
    },
    {
        tip: "May occasionally produce harmful instructions or biased",
        text: "Give me 10 date-night ideas for me and my partner but include ideas that we can do in the house, outdoors and within a 10-mile radius.",
        value: "Give me 10 date-night ideas for me and my partner but include ideas that we can do in the house, outdoors and within a 10-mile radius.",
        image: dateNight,
        type: "chat"
        // title: "Limitations",
        // image: "src/assets/exclamation.svg"
    },
    {
        tip: "Trained to decline inappropriate requests",
        text: "Help me create a lesson plan for the next four weeks. I'm teaching 8-year-olds about Geography.",
        value: "Help me create a lesson plan for the next four weeks. I'm teaching 8-year-olds about Geography.",
        image: geographyLessonPlan,
        type: "chat"
    },
    {
        tip: "Allows user to provide follow-up corrections",
        text: "Act like an experienced personal trainer who know the best techniques. Describe a set of stretches and exercises that are proven to help ease back pain.",
        value: "Act like an experienced personal trainer who know the best techniques. Describe a set of stretches and exercises that are proven to help ease back pain.",
        image: personalTrainer,
        type: "chat"
    },
    {
        tip: "Ask follow up questions and engage",
        text: "Give me a basic guide on how to cultivate a growth mindset.",
        value: "Give me a basic guide on how to cultivate a growth mindset.",
        image: growthMindset,
        type: "chat"
    },
    {
        tip: "Add adjectives to tweak the overall look of the image.",
        text: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        value: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
        image: astraunautArticle,
        type: "image"
    },
    {
        tip: "Be specific about what you want to see in an image.",
        text: "3D render of a cute orange monster on a dark blue background, digital art",
        value: "3D render of a cute orange monster on a dark blue background, digital art",
        image: orangeMonster,
        type: "image"
    },
    {
        tip: "Consider aspects of imagery like lighting and perspective.",
        text: "A luxury Parisian penthouse, warm colors, golden hour",
        value: "A luxury Parisian penthouse, warm colors, golden hour",
        image: goldenHour,
        type: "image"
    }
];

//list for talk
export const talkExampleList = [
    {
        text: "Can you provide me with a sample agency agreement I can use.",
        value: "Can you provide me with a sample agency agreement I can use.",
        image: agreement,
        type: "chat"
    },
    {
        text: "What are the approval requirements for agency agreements?",
        value: "What are the approval requirements for agency agreements?",
        image: agreement,
        type: "chat"
    },
    {
        text: "When must consent be given for personal data processing.",
        value: "When must consent be given for personal data processing.",
        image: PersonalData,
        type: "chat"
    },
    {
        text: "Does the GDPR concern technical data, or only personal data.",
        value: "Does the GDPR concern technical data, or only personal data.",
        image: professionalInsurance,
        type: "chat"
    },
    {
        text: "What is personal data?",
        value: "What is personal data?",
        image: PersonalData,
        type: "chat"
    },
    {
        text: "Can I accept the laws of Saudi Arabia?  Switzerland?  England? Singapore?",
        value: "Can I accept the laws of Saudi Arabia?  Switzerland?  England? Singapore?",
        image: Laws,
        type: "chat"
    },
    {
        text: "What is an indemnity?",
        value: "What is an indemnity?",
        image: Indemnity,
        type: "chat"
    },
    {
        text: "Do we have professional indemnity insurance?",
        value: "Do we have professional indemnity insurance?",
        image: professionalInsurance,
        type: "chat"
    },
    {
        text: "What is the deductible of our liability insurance policy?",
        value: "What is the deductible of our liability insurance policy?",
        image: insurancePolicy,
        type: "chat"
    },
    {
        text: "What is the difference between the Compliance Approval Tool, the Third Party Tool and the Whistleblower System?",
        value: "What is the difference between the Compliance Approval Tool, the Third Party Tool and the Whistleblower System?",
        image: fi_9972405,
        type: "chat"
    }
];
//use case Example list
export const useCaseExampleList = [
    {
        text: "Can you provide me with a sample agency agreement I can use.",
        value: "Can you provide me with a sample agency agreement I can use.",
        image: agreement,
        type: "chat"
    },
    {
        text: "What are the approval requirements for agency agreements?",
        value: "What are the approval requirements for agency agreements?",
        image: agreement,
        type: "chat"
    },
    {
        text: "When must consent be given for personal data processing.",
        value: "When must consent be given for personal data processing.",
        image: PersonalData,
        type: "chat"
    },
    {
        text: "Does the GDPR concern technical data, or only personal data.",
        value: "Does the GDPR concern technical data, or only personal data.",
        image: PersonalData,
        type: "chat"
    },
    {
        text: "What is personal data?",
        value: "What is personal data?",
        image: PersonalData,
        type: "chat"
    },
    {
        text: "Can I accept the laws of Saudi Arabia?  Switzerland?  England? Singapore?",
        value: "Can I accept the laws of Saudi Arabia?  Switzerland?  England? Singapore?",
        image: Laws,
        type: "chat"
    },
    {
        text: "What is an indemnity?",
        value: "What is an indemnity?",
        image: Indemnity,
        type: "chat"
    },
    {
        text: "Do we have professional indemnity insurance?",
        value: "Do we have professional indemnity insurance?",
        image: professionalInsurance,
        type: "chat"
    },
    {
        text: "What is the deductible of our liability insurance policy?",
        value: "What is the deductible of our liability insurance policy?",
        image: insurancePolicy,
        type: "chat"
    },
    {
        text: "What is the difference between the Compliance Approval Tool, the Third Party Tool and the Whistleblower System?",
        value: "What is the difference between the Compliance Approval Tool, the Third Party Tool and the Whistleblower System?",
        image: fi_9972405,
        type: "chat"
    }
];
//brazil tax state list
export const brazilTaxStateExampleList = [
    { key: "AC", text: "Acre", county_Code: "BR" },
    { key: "AL", text: "Alagoas", county_Code: "BR" },
    { key: "AM", text: "Amazon", county_Code: "BR" },
    { key: "AP", text: "Amapa", county_Code: "BR" },
    { key: "BA", text: "Bahia", county_Code: "BR" },
    { key: "CE", text: "Ceara", county_Code: "BR" },
    { key: "DF", text: "Brasilia", county_Code: "BR" },
    { key: "ES", text: "Espirito Santo", county_Code: "BR" },
    { key: "GO", text: "Goias", county_Code: "BR" },
    { key: "MA", text: "Maranhao", county_Code: "BR" },
    { key: "MG", text: "Minas Gerais", county_Code: "BR" },
    { key: "MS", text: "Mato Grosso do Sul", county_Code: "BR" },
    { key: "MT", text: "Mato Grosso", county_Code: "BR" },
    { key: "PA", text: "Para", county_Code: "BR" },
    { key: "PB", text: "Paraiba", county_Code: "BR" },
    { key: "PE", text: "Pernambuco", county_Code: "BR" },
    { key: "PI", text: "Piaui", county_Code: "BR" },
    { key: "PR", text: "Parana", county_Code: "BR" },
    { key: "RJ", text: "Rio de Janeiro", county_Code: "BR" },
    { key: "RN", text: "Rio Grande do Norte", county_Code: "BR" },
    { key: "RO", text: "Rondonia", county_Code: "BR" },
    { key: "RR", text: "Roraima", county_Code: "BR" },
    { key: "RS", text: "Rio Grande do Sul", county_Code: "BR" },
    { key: "SC", text: "Santa Catarina", county_Code: "BR" },
    { key: "SE", text: "Sergipe", county_Code: "BR" },
    { key: "SP", text: "Sao Paulo", county_Code: "BR" },
    { key: "TO", text: "Tocantins", county_Code: "BR" }
];

//brazil tax country list
export const brazilTaxCountryExampleList = [
    {
        text: "Brazil",
        key: "BR"
    }
];

//brazil tax city list
export const brazilTaxExampleData4 = {
    rows: [
        {
            State: "SP",
            City: "Itupeva"
        },
        {
            State: "SP",
            City: "Santa Isabel"
        }
    ]
};

//brazil tax postal code list
export const brazilTaxExampleData = {
    rows: [
        {
            State: "SP",
            City: "Itupeva",
            Postal_Code_From: "13295-000",
            Postal_Code_To: "13299-999"
        },
        {
            State: "SP",
            City: "Santa Isabel",
            Postal_Code_From: "07500-000",
            Postal_Code_To: "07599-999"
        }
    ]
};

//brazil tax postal code list
export const brazilTaxExampleData2 = {
    rows: [
        {
            State: "SP",
            City: "Itupeva",
            Postal_Code_From: "07500-000"
        },
        {
            State: "SP",
            City: "Santa Isabel",
            Postal_Code_From: "07500-000"
        }
    ]
};

//brazil tax postal code list
export const brazilTaxExampleData3 = {
    rows: [
        {
            State: "SP",
            City: "Itupeva",
            Postal_Code_From: "13295-000",
            Postal_Code_To: "13299-999",
            Tax_Jurisdiction_Code: "3524006",
            Postal_Code_From2: "13295-000",
            Postal_Code_To2: "13299-999",
            Tax_Jurisdiction_Code2: "3524006"
        },
        {
            State: "SP",
            City: "Santa Isabel",
            Postal_Code_From: "07500-000",
            Postal_Code_To: "07599-999",
            Tax_Jurisdiction_Code: "5219357",
            Postal_Code_From2: "13295-000",
            Postal_Code_To2: "13299-999",
            Tax_Jurisdiction_Code2: "3524006"
        }
    ]
};

export const menus: MenuItemType[] = [
    {
        Description: null,
        IsActive: true,
        MenuItemID: 1,
        MenuItemName: "Standard",
        ParentId: 0,
        Path: "#",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 2,
        MenuItemName: "GEA Chat",
        ParentId: 1,
        Path: "/chat",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 3,
        MenuItemName: "Ask a Question",
        ParentId: 4,
        Path: "/qa",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 4,
        MenuItemName: "Advanced",
        ParentId: 0,
        Path: "#",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 5,
        MenuItemName: "Bing",
        ParentId: 4,
        Path: "/bing",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 6,
        MenuItemName: "ChatGPT",
        ParentId: 1,
        Path: "/purepluso",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 7,
        MenuItemName: "Text to Image",
        ParentId: 1,
        Path: "/dalle3",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 8,
        MenuItemName: "DocuSpeak",
        ParentId: 1,
        Path: "/talk",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 10,
        MenuItemName: "Use Cases",
        ParentId: 0,
        Path: "#",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 12,
        MenuItemName: "Dall-E 3",
        ParentId: 15,
        Path: "/dalle3",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 14,
        MenuItemName: "Global Function",
        ParentId: 14,
        Path: "#",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 15,
        MenuItemName: "Division",
        ParentId: 14,
        Path: "#",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 16,
        MenuItemName: "Region & Country",
        ParentId: 14,
        Path: "#",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 17,
        MenuItemName: "Ask Legal",
        ParentId: 10,
        Path: "/usecase/legal",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 18,
        MenuItemName: "GEA Mind+",
        ParentId: 14,
        Path: "/",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 19,
        MenuItemName: "GEA Academy Agent",
        ParentId: 14,
        Path: "/",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 20,
        MenuItemName: "Financial Analysis Agent",
        ParentId: 14,
        Path: "/",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 21,
        MenuItemName: "OptiPartner",
        ParentId: 15,
        Path: "/",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 22,
        MenuItemName: "Peak 2.0",
        ParentId: 15,
        Path: "/",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 23,
        MenuItemName: "Cutmaster Agent",
        ParentId: 15,
        Path: "/",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 24,
        MenuItemName: "SFT Service Agent",
        ParentId: 15,
        Path: "/",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 25,
        MenuItemName: "HRT Omni Agent",
        ParentId: 15,
        Path: "/",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: false,
        MenuItemID: 26,
        MenuItemName: "NAM HR Support",
        ParentId: 16,
        Path: "/",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 27,
        MenuItemName: "Business Users",
        ParentId: 14,
        Path: "/business_user",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 28,
        MenuItemName: "Legal Teams",
        ParentId: 14,
        Path: "/legalteams",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 29,
        MenuItemName: "Approved Chats",
        ParentId: 14,
        Path: "/legalteam/approvedchats",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 31,
        MenuItemName: "RAG Evaluation",
        ParentId: 4,
        Path: "#",
        RoleID: 5
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 30,
        MenuItemName: "Brazilian Tax",
        ParentId: 14,
        Path: "/braziltaxcode",
        RoleID: 6
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 32,
        MenuItemName: "Ground Truth",
        ParentId: 31,
        Path: "/ground_truth",
        RoleID: 6
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 33,
        MenuItemName: "Evaluation",
        ParentId: 31,
        Path: "/evaluation",
        RoleID: 6
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 34,
        MenuItemName: "Results",
        ParentId: 31,
        Path: "/result",
        RoleID: 6
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 35,
        MenuItemName: "Feedback",
        ParentId: 31,
        Path: "/feedback",
        RoleID: 6
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 36,
        MenuItemName: "sustainability",
        ParentId: 10,
        Path: "usecase/sustainability",
        RoleID: 7
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 37,
        MenuItemName: "Ask Legal Classic",
        ParentId: 10,
        Path: "usecase/asklegalclassic",
        RoleID: 7
    },
    {
        Description: null,
        IsActive: true,
        MenuItemID: 9,
        MenuItemName: "R&D Sandbox",
        ParentId: 4,
        Path: "https://oai.azure.com/portal/87ecb3f716804080b6503c744a568c85/chat?tenantid=0e17f90f-88a3-4f93-a5d7-cc847cff307e",
        RoleID: 5
    }
];

export const historyThreads: ChatThread[] = [
    {
        ChatMode: "GEAGPT Chat",
        ChatModel: "gpt-4",
        ChatType: "CHAT_THREAD",
        CogIndex: "gptkbindex",
        ConversationStyle: "precise",
        Filename: "",
        Id: "db588e9b-b568-498d-a0c0-e9e9555b0dec",
        IsDeleted: false,
        Name: "How is GEA leveraging R&D and sustainable solutions to reduce energy and water consumption?",
        ThreadUrl: "/chat/db588e9b-b568-498d-a0c0-e9e9555b0dec",
        Timestamp: new Date().toISOString(),
        UserId: "db0d5925"
    },
    {
        ChatMode: "Pure Plus",
        ChatModel: "gpt-4",
        ChatType: "CHAT_THREAD",
        CogIndex: "gptkbindex",
        ConversationStyle: "precise",
        Filename: "",
        Id: "c6345a9d-85d8-4fad-9f8c-3b860a08e254",
        IsDeleted: false,
        Name: "generate code for 2 num sum",
        ThreadUrl: "/pureplus/c6345a9d-85d8-4fad-9f8c-3b860a08e254",
        Timestamp: new Date().toISOString(),
        UserId: "db0d5925"
    },
    {
        ChatMode: "GPT 4o",
        ChatModel: "gpt-4",
        ChatType: "CHAT_THREAD",
        CogIndex: "gptkbindex",
        ConversationStyle: "precise",
        Filename: "",
        Id: "d32abd05-3f8d-4a9a-aac0-633647f17306",
        IsDeleted: false,
        Name: 'is this MongoDB query correct? if not provide updated new query db.getCollection(\'flights\') .find({ "destination.location": { $near: { $geometry: { type: "Point", coordinates: [26.1, 44.4] }, $maxDistance: 100000 } } })',
        ThreadUrl: "/purepluso/d32abd05-3f8d-4a9a-aac0-633647f17306",
        Timestamp: new Date().toISOString(),
        UserId: "db0d5925"
    },
    {
        ChatMode: "Usecase sustainability",
        ChatModel: "gpt-4",
        ChatType: "CHAT_THREAD",
        CogIndex: "gptkbindex",
        ConversationStyle: "precise",
        Filename: "",
        Id: "d8ddcc5d-54f8-4e83-856f-128d1bcfdaaf",
        IsDeleted: false,
        Name: "Can you provide me with a sample agency agreement I can use.",
        ThreadUrl: "/usecase/legal/d8ddcc5d-54f8-4e83-856f-128d1bcfdaaf",
        Timestamp: new Date().toISOString(),
        UserId: "db0d5925"
    },
    {
        ChatMode: "Usecase legal",
        ChatModel: "gpt-4",
        ChatType: "CHAT_THREAD",
        CogIndex: "gptkbindex",
        ConversationStyle: "precise",
        Filename: "",
        Id: "c889267a-25c1-4ea5-ba46-c9e47930d9c6",
        IsDeleted: false,
        Name: "What is the deductible of our liability insurance policy?",
        ThreadUrl: "/usecase/legal/c889267a-25c1-4ea5-ba46-c9e47930d9c6",
        Timestamp: new Date().toISOString(),
        UserId: "db0d5925"
    },
    {
        ChatMode: "Bing",
        ChatModel: "gpt-4",
        ChatType: "CHAT_THREAD",
        CogIndex: "gptkbindex",
        ConversationStyle: "precise",
        Filename: "",
        Id: "26c3676b-3b8e-464d-bdcb-c03f345cb01b",
        IsDeleted: false,
        Name: "Should I have a hometown wedding or a destination wedding?",
        ThreadUrl: "/bing/26c3676b-3b8e-464d-bdcb-c03f345cb01b",
        Timestamp: new Date().toISOString(),
        UserId: "db0d5925"
    },
    {
        ChatMode: "GEAGPT Ask",
        ChatModel: "gpt-4",
        ChatType: "CHAT_THREAD",
        CogIndex: "gptkbindex",
        ConversationStyle: "precise",
        Filename: "",
        Id: "6d8700a4-618a-428f-b9db-184862b919c4",
        IsDeleted: false,
        Name: "Can you tell me about the Add Better Ecolabel?",
        ThreadUrl: "/qa/6d8700a4-618a-428f-b9db-184862b919c4",
        Timestamp: new Date().toISOString(),
        UserId: "db0d5925"
    },
    {
        ChatMode: "Talk",
        ChatModel: "",
        ChatType: "CHAT_THREAD",
        CogIndex: "",
        ConversationStyle: "",
        Filename: "",
        Id: "f6569775-6205-4b8e-a3d7-51692307a618",
        IsDeleted: false,
        Name: "what is this doc about?",
        ThreadUrl: "/talk/f6569775-6205-4b8e-a3d7-51692307a618",
        Timestamp: new Date().toISOString(),
        UserId: "db0d5925"
    },
    {
        ChatMode: "GPT 4o",
        ChatModel: "gpt-4",
        ChatType: "CHAT_THREAD",
        CogIndex: "gptkbindex",
        ConversationStyle: "precise",
        Filename: "",
        Id: "1ba6d12a-f100-403c-810b-abd05ae98bb1",
        IsDeleted: false,
        Name: "Write me a 500-word blog post about tips on creating a social media content calendar for e-commerce. Make it informal and fun.",
        ThreadUrl: "/purepluso/1ba6d12a-f100-403c-810b-abd05ae98bb1",
        Timestamp: new Date().toISOString(),
        UserId: "db0d5925"
    }
];

export const domainNames = [
    ".com",
    ".org",
    ".net",
    ".edu",
    ".gov",
    ".io",
    ".co",
    ".us",
    ".uk",
    ".ca",
    ".de",
    ".jp",
    ".fr",
    ".au",
    ".ru",
    ".ch",
    ".it",
    ".nl",
    ".se",
    ".no",
    ".es",
    ".mil",
    ".biz",
    ".info",
    ".mobi",
    ".name",
    ".pro",
    ".tv",
    ".asia",
    ".me",
    ".cn",
    ".in",
    ".mx",
    ".br",
    ".za",
    ".kr",
    ".eu",
    ".ar",
    ".pl",
    ".tr",
    ".gr",
    ".pt",
    ".dk",
    ".fi",
    ".ie",
    ".nz",
    ".sg",
    ".hk",
    ".il",
    ".my",
    ".ae",
    ".sa",
    ".cl",
    ".th",
    ".vn",
    ".tw",
    ".id",
    ".pk",
    ".ph"
];

export const paramsFromAPI = {
    FAQ_1: "What is Engineering with purpose?",
    FAQ_2: "How is GEA leveraging R&D and sustainable solutions to reduce energy and water consumption?",
    FAQ_3: "Astronaut wearing a gold jumpsuit and helmet on Mars. photo-realistic",
    FAQ_4: "3D render of a cute orange monster on a dark blue background, digital art",
    FAQ_5: "A luxury Parisian penthouse, warm colors, golden hour",
    FAQ_6: "Can you tell me about the Add Better Ecolabel?",
    WelcomeStatement: "Welcome to the sustainability use case, How may I help you today?",
    IconImages: [
        "https://cdn.gea.com/-/jssmedia/common/logos/gea-logo.svg",
        "https://cdn.gea.com/-/jssmedia/common/logos/gea-logo.svg",
        "https://cdn.gea.com/-/jssmedia/common/logos/gea-logo.svg",
        "https://cdn.gea.com/-/jssmedia/common/logos/gea-logo.svg",
        "https://cdn.gea.com/-/jssmedia/common/logos/gea-logo.svg",
        "https://cdn.gea.com/-/jssmedia/common/logos/gea-logo.svg"
    ],
    LogoHeight: 50,
    LogoWidth: 50,
    QueryPrompt: null,
    deploymentName: "chat",
    fewShotExample: null,
    filePath: "https://geagptstorageterra.blob.core.windows.net/ucconfig/error.txt",
    frequencyPenalty: 0,
    id: 1,
    imagePath: "https://geagptstorageterra.blob.core.windows.net/ucconfig/asklegal1.png",
    image: "https://geagptstorageterra.blob.core.windows.net/ucconfig/asklegal1.png",
    maxResponseLength: 40,
    pastMessagesToInclude: 10,
    presencePenalty: 0,
    stopSequences: "None",
    systemPrompt:
        " You are an AI assistant named GEAGPT that helps people find information. Use the above questions and answers as examples to guide your responses. ",
    temperature: 0.0,
    topProbabilities: 1.0,
    use_case_name: "sustainability",
    IsVisionEnabled: true,
    IsFileUpload: true,
    NumDocRAG: 5
};
