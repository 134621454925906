import React, { useEffect, useState } from "react";
import { RagasContext } from "./RagasContext";
import { IDropdownOption } from "@fluentui/react";
type Props = {
    children: React.ReactNode;
};

// MenuProvider is a context provider that provides the selected menu item, a function to set the selected menu item, a function to generate a PDF, a reference to the target element, and a flag to indicate whether the answers are being displayed.
export function RagasProvider({ children }: Props) {
    const [selectedUseCase, setSelectedUseCase] = React.useState<IDropdownOption>({ key: "", text: "" });
    const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
    const [comment, setComment] = useState<string>("");
    const [fileUploadSuccess, setFileUploadSuccess] = useState<boolean>(false);
    const [downloadableData, setDownloadableData] = useState<any[]>([]);

    const value = {
        // useCaseOptions,
        // setUseCaseOptions,
        selectedUseCase,
        setSelectedUseCase,
        selectedMetrics,
        setSelectedMetrics,
        comment,
        setComment,
        fileUploadSuccess,
        setFileUploadSuccess,
        downloadableData, setDownloadableData
    };

    return <RagasContext.Provider value={value}>{children}</RagasContext.Provider>;
}
