import styles from "./Loading.module.css";
import gea_logo_white from "../../assets/gea_logo_white.png";
import { Link } from "react-router-dom";

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
    const { userId, isLoaded, roll } = JSON.parse(sessionStorage.getItem("user") || "{}");

    return (
        <>
            <div className={styles.bgImage}></div>
            <img src={gea_logo_white} width={220} className={`${styles.gealogo} `} />
            <div className={styles.bgText}>
                <span className={styles.loader} />
            </div>
        </>
    );
};

export default Loading;
